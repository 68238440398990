import { create } from "zustand";
import { persist } from "zustand/middleware";
const useStore = create(
  persist(
    (set) => ({
      currentPage: 1,
      totalPages: 12,
      setCurrentPage: (page) => set({ currentPage: page }),
      scores: { e: 0, i: 0, s: 0, n: 0, t: 0, f: 0, p: 0, j: 0 },
      selectedAnswers: [],
      addScore: (type) =>
        set((state) => ({
          scores: {
            ...state.scores,
            [type]: state.scores[type] + 1,
          },
        })),
      addAnswer: (answer) =>
        set((state) => ({
          selectedAnswers: [...state.selectedAnswers, answer],
        })),
      resetScores: () =>
        set({
          scores: { e: 0, i: 0, s: 0, n: 0, t: 0, f: 0, p: 0, j: 0 },
          selectedAnswers: [],
        }),
      getMBTIResult: () => {
        const state = useStore.getState();
        const { e, i, s, n, t, f, p, j } = state.scores;
        const mbti = `${e >= i ? "E" : "I"}${s >= n ? "S" : "N"}${
          t >= f ? "T" : "F"
        }${p >= j ? "P" : "J"}`;
        return mbti;
      },
      pageContents: [
        {
          text: "운동이나 취미로 인해 함께하는 시간이 부족한 상황...",
          textBtn1: {
            text: "나도 개인적인 시간이 필요해서 이해한다",
            type: "e",
            result: "같은 취미를 가지면 매우 재미있는 연애를 할 수 있어요",
          },
          textBtn2: {
            text: "솔직히 조금 서운하다",
            type: "i",
            result: "서로에게 집중할 수 있는 시간과 여유가 있는 상대가 좋아요",
          },
        },
        {
          text: "연인이 친구들과 모임중에 같이 나와서 놀자고 한다",
          textBtn1: {
            text: "그래? 재미는 있겠는데 갈까 말까 한번 고민해 본다",
            type: "e",
            result: "사람들과 함께할 줄 아는 상대가 잘 맞아요",
          },
          textBtn2: {
            text: "잘 모르는 사람들과의 자리는 불편하다 잘 둘러서 거절한다",
            type: "i",
            result: "혼자만의 시간을 즐길 수 있는 사람이 잘 맞아요",
          },
        },
        {
          text: "연인이 캠핑 중독이라 매주 캠핑을 가자고 한다",
          textBtn1: {
            text: "매주 캠핑은 힘드니 집에서 쉬자고 타협한다",
            type: "i",
            result: "나가서 쉬는건 쉬는게 아닌걸 이해해 줘야해요ㅠ",
          },
          textBtn2: {
            text: "캠핑도 좋지만 다른 재미있는 것도 해보자고 한다",
            type: "e",
            result: "함께 다양한 활동을 경험할 수 있는 사람이 이상적이에요",
          },
        },
        {
          text: "미래에 대해 진지한 대화 중 천만유튜버가 되겠다고 한다",
          textBtn1: {
            text: "뭔 말도 안되는 소리? 좀 답답하다",
            type: "s",
            result: "당장의 고민이나 현실적인 이야기를 터놓을 상대가 필요해요",
          },
          textBtn2: {
            text: "일단 들어나 보자, 더 이야기 해보라고 한다",
            type: "n",
            result: "기발하고 재미있게 이야기하는 상대가 매력적이에요",
          },
        },
        {
          text: "대화를 하다가 싸우게 되었다, 더 싫은 상대방의 행동은?",
          textBtn1: {
            text: "나로서는 이해할 수 없는 상대만의 논리",
            type: "s",
            result: "대화가 안 통하면 실망스러워요 :(",
          },
          textBtn2: {
            text: "팩트 기반이지만 조금의 양보도 하지 않는 태도",
            type: "n",
            result: "개인주의가 강하면 실망스러워요 :(",
          },
        },
        {
          text: "연인이 요리를 좋아해서 매일 만들어 준다, 원하는 요리는?",
          textBtn1: {
            text: "맛보장 100%, 항상 비슷하지만 정갈한 요리",
            type: "s",
            result: "편안한 일상에서도 행복함을 느낄 수 있는 사람 강력추천!!!",
          },
          textBtn2: {
            text: "맛보장 불가, 15%의 로또 확률의 다양한 요리",
            type: "n",
            result: "새로운 것에 항상 도전해 보는 사람 강력추천!!!",
          },
        },
        {
          text: "연인이 비싼 물건을 생일 선물로 주었는데 편지가 없다",
          textBtn1: {
            text: "생각을 많이 해준 느낌이라 괜찮다",
            type: "t",
            result: "표현하지 않아도 알아요, 서로의 신뢰가 중요해요",
          },
          textBtn2: {
            text: "그래도 짧은 편지정도는 써주면 좋겠다",
            type: "f",
            result: "서로의 감정을 잘 나눌 수 있는 사람이 중요해요",
          },
        },
        {
          text: "연애한지 500일, 까먹고 그냥 집에서 넷플릭스 봄...",
          textBtn1: {
            text: "잉 오백일? 오백일도 챙겨야 되는건가?",
            type: "t",
            result: "큰일에 발벗고 나설 수 있는 사람이 멋져보여요乃",
          },
          textBtn2: {
            text: "서운하진 않지만 그래도 알고는 있어야지!",
            type: "f",
            result: "작은 일도 기억하고 챙겨주는 상대가 예뻐보여요♡",
          },
        },
        {
          text: "만나다 보니 연인의 감정 기복이 심한 편이다",
          textBtn1: {
            text: "뭔가 좀 힘든 것 같다",
            type: "t",
            result: "감정적으로 안정적인 사람이면 좋겠어요",
          },
          textBtn2: {
            text: "사랑으로 잘 극복하자!",
            type: "f",
            result: "모든 가치관 중, 사랑이 최우선인 것이 중요해요",
          },
        },
        {
          text: "연휴에 2박 3일 가기로 했다 계획은 누가?",
          textBtn1: {
            text: "내가 하는 게 좋다",
            type: "p",
            result: "내가 세운 계획을 잘 따라 주는 사람이 고마워요",
          },
          textBtn2: {
            text: "귀찮다, 해줬으면 좋겠다",
            type: "j",
            result: "계획을 세우는건 귀찮아요 주도적인 상대가 필요해요",
          },
        },
        {
          text: "데이트 중 가려던 음식점에 줄이 길다",
          textBtn1: {
            text: "기다렸다가 먹자",
            type: "j",
            result: "하고 싶은 건 꼭 해야돼! 당신을 포용할 수 있으면 좋아요 :)",
          },
          textBtn2: {
            text: "다른 집으로 간다",
            type: "p",
            result: "상대에게 잘 맞출 줄 아는 당신! 열정적인 사람도 좋아요 :)",
          },
        },
        {
          text: "연인과 함께 제주도 한달 살기 체험! 청소는?",
          textBtn1: {
            text: "매일매일 각자 임무 분담",
            type: "j",
            result: "서로 약속된 생활 패턴을 지키면 싸울일이 없어요!",
          },
          textBtn2: {
            text: "2, 3일에 한 번 날 잡아서 대청소",
            type: "p",
            result: "서로의 시간을 존중하고 여유를 가지면 싸울일이 없어요!",
          },
        },
      ],
    }),
    {
      name: "mbti-storage", // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본값은 localStorage
    }
  )
);

export default useStore;
