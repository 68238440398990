import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const logoImage = require("../assets/images/luvnnect_logo_bk.webp");

const UserSignout = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // 상태 추가

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // 제출 시작 시 비활성화 상태로 설정

    try {
      const response = await fetch('https://treeapps.io/luvnnect/user/signout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        toast.success('탈퇴 요청 완료 :)');
        setResetSuccess(true);
      } else {
        toast.error('탈퇴 요청 실패 :(');
      }
    } catch (error) {
      console.error('Error user signout:', error);
      toast.error('Failed to signout');
    } finally {
      setIsSubmitting(false); // 응답 후 비활성화 상태 해제
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4 sm:p-8">
      <div className="bg-white p-6 rounded-xl shadow-md w-full max-w-xs">
        <ToastContainer />
        <div className="flex justify-center mb-7 fade-in">
          <img src={logoImage} alt="luvnnect" className="h-11 opacity-80"/>
        </div>
        {resetSuccess ? (
          <p className="text-center mb-2 text-sm text-[#7775f9] font-bold fade-in">러브넥트를 이용해 주셔서 감사합니다 :)</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-[#7775f9] font-semibold font-sans text-sm">이메일을 입력하세요</label>
              <input
                type="email"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isSubmitting} // 비활성화 상태 반영
              />
            </div>
            <div className="mb-4">
              <label className="block text-[#7775f9] font-semibold font-sans text-sm">비밀번호를 입력하세요</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isSubmitting} // 비활성화 상태 반영
              />
            </div>
            <button
              type="submit"
              className={`w-full p-2 rounded mt-4 font-semibold font-sans ${
                isSubmitting
                  ? 'bg-gray-400 cursor-not-allowed text-white'
                  : 'bg-[#918FCC] text-white'
              }`}
              disabled={isSubmitting} // 비활성화 상태 반영
            >
              회원 탈퇴를 요청합니다
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserSignout;
