import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "../store/useStore";
import "../css/style.css";

const mainImage = require("../assets/images/complete_bg.webp");
const logoImage = require("../assets/images/luvnnect_logo.webp");
const eventImage = require("../assets/images/insta_mgc.webp");

function LoadingView() {
  const { selectedAnswers, getMBTIResult } = useStore((state) => ({
    selectedAnswers: state.selectedAnswers,
    getMBTIResult: state.getMBTIResult,
  }));

  const navigate = useNavigate();
  const [showLogo, setShowLogo] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(true);
    }, 1000);

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  useEffect(() => {
    let loadedImagesCount = 0;

    const handleImageLoad = () => {
      loadedImagesCount++;
      if (loadedImagesCount === 3) {
        setImagesLoaded(true);
      }
    };

    const imagesToLoad = [mainImage, logoImage];
    const mbti = getMBTIResult();
    const mbtiImageUrl = require(`../assets/images/mbti/${mbti}.webp`);
    imagesToLoad.push(mbtiImageUrl);

    imagesToLoad.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
    });
  }, [getMBTIResult]);

  const handleDownloadClick = () => {
    window.open("https://treeapps.io/luvnnect/link", "_blank"); // 다운로드 이미지 클릭 시 열리는 링크
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/luvnnect", "_blank"); // 원하는 URL로 변경
  };

  const handleEventClick = () => {
    window.open(
      "https://www.instagram.com/p/C_eQgx9BQ0o/?igsh=aDVjOWVjejRnN3N5",
      "_blank"
    ); // 원하는 URL로 변경
  };

  const handleReplayButtonClick = () => {
    navigate("/mbti"); // 원하는 경로로 변경
  };

  const handleLinkButtonClick = () => {
    const link = "https://luvnnect.com/mbti"; // 클립보드에 복사할 링크
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("클립보드에 저장되었습니다 :)", { autoClose: 1500 });
      })
      .catch((err) => {
        toast.error("에러 발생 ㅠ", { autoClose: 1500 });
      });
  };

  const mbti = getMBTIResult();
  const filteredAnswers = selectedAnswers.filter((answer) => {
    const type = answer.type;
    return type && mbti.includes(type.toUpperCase());
  });

  const mbtiImageUrl = require(`../assets/images/mbti/${mbti}.webp`);

  if (!imagesLoaded) {
    return <div></div>;
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="header-bg fade-in"></div>
      <div className="flex flex-col items-center justify-center min-h-screen header-container">
        <div
          className="center-content fade-in"
          style={{ width: "100%", maxWidth: "600px" }}
        >
          <div
            className="image-container"
            style={{ position: "relative", width: "100%", height: "auto" }}
          >
            <img
              src={mainImage}
              alt="Completion Background"
              className="w-full h-auto"
            />
            <img
              src={mbtiImageUrl}
              alt={`${mbti} Image`}
              className="absolute fade-in-speed"
              style={{
                maxWidth: "80%",
                top: "16.5%",
                left: "50%",
                transform: "translate(-50%, -20%)",
              }}
            />

            <button
              onClick={handleReplayButtonClick}
              style={{
                position: "absolute",
                top: "12%",
                right: "50%", // 이미지의 가운데에서 좌측으로 이동
                width: "40%",
                height: "15%",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            ></button>
            <button
              onClick={handleLinkButtonClick}
              style={{
                position: "absolute",
                top: "12%",
                left: "50%", // 이미지의 가운데에서 우측으로 이동
                width: "40%",
                height: "15%",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            ></button>

            <div
              className="absolute inset-0 flex flex-col items-center justify-start text-center text-gray-500"
              style={{ top: "31.2%" }}
            >
              <ul className="answer-list font-['target'] fade-in">
                {filteredAnswers.map((answer, index) => (
                  <li key={index} className="mb-2 text-sm">
                    {answer.result}
                  </li>
                ))}
              </ul>
            </div>
            {/* 왼쪽 버튼 */}
            <button
              onClick={handleDownloadClick}
              style={{
                position: "absolute",
                bottom: "10%",
                left: "7%", // 이미지의 가운데에서 좌측으로 이동
                width: "32%",
                height: "10%",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Replay"
            />
            {/* 오른쪽 버튼 */}
            <button
              onClick={handleInstagramClick}
              style={{
                position: "absolute",
                bottom: "0%",
                right: "5%", // 이미지의 가운데에서 우측으로 이동
                width: "55%",
                height: "25%",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Link"
            />
          </div>

          {/* 이벤트 이미지 추가 */}
          <div className="flex mt-4 fade-in" style={{ width: "70%" }}>
            <img
              // onClick={handleEventClick}
              src={eventImage}
              alt="Event"
              className="w-full h-auto"
            />
          </div>
        </div>

        {showLogo && (
          <div className="flex justify-center mt-4 mb-2">
            <img src={logoImage} alt="Badge" className="w-1/2 h-auto " />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoadingView;
