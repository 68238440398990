import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const logoImage = require("../assets/images/luvnnect_logo_bk.webp");

const UserPwreset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false); // 상태 추가
  const [isSubmitting, setIsSubmitting] = useState(false); // 상태 추가
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const oobCode = query.get('oobCode');

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      toast.error('비밀번호는 영어, 숫자, 특수문자 조합의 8자리 이상이어야 합니다.');
      return;
    }

    setIsSubmitting(true); // 제출 시작 시 비활성화 상태로 설정

    try {
      const response = await fetch('https://treeapps.io/luvnnect/user/pwreset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ oobCode, newPassword }),
      });

      if (response.ok) {
        toast.success('비밀번호 변경 완료 :)');
        setResetSuccess(true); // 상태 변경
      } else {
        toast.error('비밀번호 변경 실패 :(');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Failed to reset password');
    } finally {
      setIsSubmitting(false); // 응답 후 비활성화 상태 해제
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4 sm:p-8">
      <div className="bg-white p-6 rounded-xl shadow-md w-full max-w-xs">
        <ToastContainer />
        <div className="flex justify-center mb-7 fade-in">
          <img src={logoImage} alt="luvnnect" className="h-11 opacity-82"/>
        </div>
        {resetSuccess ? (
          <p className="text-center mb-2 text-sm text-[#7775f9] font-bold fade-in">러브넥트가 당신의 사랑을 응원합니다 :)</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-[#7775f9] font-semibold font-sans text-sm">새로운 비밀번호를 입력하세요</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                disabled={isSubmitting} // 비활성화 상태 반영
              />
            </div>
            <button
              type="submit"
              className={`w-full p-2 rounded mt-4 font-semibold font-sans ${
                isSubmitting
                  ? 'bg-gray-400 cursor-not-allowed text-white'
                  : 'bg-[#918FCC] text-white'
              }`}
              disabled={isSubmitting} // 비활성화 상태 반영
            >
              비밀번호 변경
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserPwreset;
