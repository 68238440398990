import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import useStore from "../store/useStore";

const images = Array.from({ length: 12 }, (_, i) =>
  require(`../assets/images/mbtiluv_${i + 1}.webp`)
);
const gifSrc = require("../assets/videos/download.gif"); // GIF 파일 경로
const initialImage = require("../assets/images/contents_insta.webp"); // 첫 이미지 경로
const logoImage = require("../assets/images/luvnnect_logo.webp");

function StartView() {
  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageContents,
    addScore,
    addAnswer,
    resetScores,
  } = useStore();
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/mbti" ||
      location.pathname === "/"
    ) {
      // 현재 경로가 '/mbti'일 때 상태 초기화
      resetScores();
      setCurrentPage(1);
    }
  }, [location.pathname]);

  useEffect(() => {
  }, [currentPage]);

  useEffect(() => {
    let loadedImagesCount = 0;

    const handleImageLoad = () => {
      loadedImagesCount++;
      if (loadedImagesCount === images.length + 2) {
        setImagesLoaded(true);
      }
    };

    // 모든 이미지를 미리 로드
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
    });

    // GIF와 첫 이미지를 로드
    const gifImage = new Image();
    gifImage.src = gifSrc;
    gifImage.onload = handleImageLoad;

    const initialImg = new Image();
    initialImg.src = initialImage;
    initialImg.onload = handleImageLoad;

  }, []);

  const nextPage = (type, result) => {
    if (buttonsDisabled) return; // 이미 버튼이 비활성화된 경우 함수 종료
    setButtonsDisabled(true); // 버튼 비활성화
    addScore(type);
    addAnswer({ type, result }); // 선택한 답변과 타입 저장
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setButtonsDisabled(false); // 버튼 다시 활성화
    } else {
      setIsFadingOut(true);
      setTimeout(() => {
        navigate("/mbti/luv");
        setCurrentPage(1);
        setButtonsDisabled(false); // 버튼 다시 활성화
      }, 1500);
    }
  };

  const handleHeaderClick = () => {
    window.open("http://instagram.com/luvnnect", "_blank"); // 원하는 URL로 변경
  };

  const handleInitialClick = () => {
    resetScores();
    setShowInitialImage(false);
  };

  const currentPageContent = pageContents[currentPage - 1] || {
    text: "Default Text",
    textBtn1: { text: "질문1", type: "e", result: "Default Answer 1" },
    textBtn2: { text: "질문2", type: "i", result: "Default Answer 2" },
  };

  if (!imagesLoaded) {
    return <div></div>;
  }

  return (
    <div
      className={`min-h-screen bg-white p-4 flex justify-center ${
        isFadingOut ? "fade-out" : "fade-in"
      }`}
    >
      {/* <Helmet>
        <meta
          property="og:url"
          content="https://luvnnect.com/mbti"
        />
        <meta property="og:title" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/treeapps-public.appspot.com/o/contents%2Fthumbnails%2Fmbtiluv.webp?alt=media&token=ee4256db-fd8e-44bf-8ff7-0987ad2d9881"
        />
      </Helmet> */}
      <div className="header-bg"></div> {/* 상단 배경 색상 추가 */}
      <div className="w-full max-w-sm relative">
        <div className="relative" style={{ aspectRatio: "2.8 / 1" }}>
          <img
            className="absolute top-0 left-0 w-full h-full object-cover rounded-t-lg bg-black"
            src={gifSrc}
            alt="Intro GIF"
            onClick={handleHeaderClick}
          />
        </div>

        {!showInitialImage && (
          <div className="relative top-[0px] left-0 w-full h-5 bg-gray-200">
            <div
              className="absolute inset-0 flex justify-center items-center text-xs text-gray-200  font-['123RF']"
              style={{ zIndex: 10 }}
            >
              러브넥트는 사람과 사랑을 연결합니다 :)
            </div>
            <div
              className="relative h-full bg-gradient-to-r from-[#918fcc9e] to-[#5e58fff4]"
              style={{ width: `${(currentPage / totalPages) * 100}%` }}
            ></div>
          </div>
        )}
        {showInitialImage && (
          <div className="relative top-[0px] left-0 w-full h-5 bg-gray-200"></div>
        )}

        <div
          className="relative mt-4 border-4 border-dashed border-gray-400 rounded-lg w-full"
          style={{ aspectRatio: "1 / 1" }}
        >
          <img
            src={images[currentPage - 1]}
            alt="Example"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          {showInitialImage && (
            <img
              src={initialImage}
              alt="Initial"
              className="absolute top-0 left-0 w-full h-full object-cover"
              style={{ zIndex: 10 }}
            />
          )}
        </div>

        {showInitialImage && (
          <p className="text-center my-4 text-xl font-['target'] text-gray-800 fade-in">
            {"나와 가장 잘 맞는 연애 상대의 MBTI를 알아보자!"}
          </p>
        )}
        {!showInitialImage && (
          <p className="text-center my-4 text-xl font-['target'] text-gray-800">
            {currentPageContent.text}
          </p>
        )}

        <div className="space-y-4 w-full relative fade-in">
          <button
            className="w-full px-4 py-2 h-12 bg-[#918FCC] rounded-lg  text-white text-lg font-['target']"
            onClick={() =>
              nextPage(
                currentPageContent.textBtn1.type,
                currentPageContent.textBtn1.result
              )
            }
            disabled={buttonsDisabled}
          >
            {currentPageContent.textBtn1.text}
          </button>
          <button
            className="w-full px-4 py-2 h-12 bg-[#918FCC] rounded-lg text-white text-lg font-['target']"
            onClick={() =>
              nextPage(
                currentPageContent.textBtn2.type,
                currentPageContent.textBtn2.result
              )
            }
            disabled={buttonsDisabled}
          >
            {currentPageContent.textBtn2.text}
          </button>

          {showInitialImage && (
            <div
              className="absolute left-0 right-0 bottom-0"
              style={{ zIndex: 20, top: "auto", height: "calc(100%)" }}
            >
              <button
                className="w-full h-full bg-[#7775f9] text-white rounded-lg text-sm font-semibold"
                onClick={handleInitialClick}
                disabled={buttonsDisabled}
              >
                <span className="animate-pulse text-2xl font-bold font-['123RF']">
                  시작하기
                </span>
              </button>
            </div>
          )}
        </div>

        {/* 여기서부터 추가된 부분입니다 */}
        <div className="flex justify-center mt-7">
          <img src={logoImage} alt="Badge" className="w-7/12 h-auto" />
        </div>
      </div>
    </div>
  );
}

export default StartView;
