import React from "react";
import MbtiView from "./views/MbtiView.js";
import MbtiluvView from "./views/MbtiluvView.js";
import { Routes, Route, Navigate } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import UserSignout from "./views/UserSignout.js";
import UserPwreset from "./views/UserPwreset.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCXZ4AF3TkPYyY31-8NLoa5YslXIV0ZjfY",
    authDomain: "treeapps-luvnnect.firebaseapp.com",
    databaseURL: "https://treeapps-luvnnect-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "treeapps-luvnnect",
    storageBucket: "treeapps-luvnnect.appspot.com",
    messagingSenderId: "1022743451273",
    appId: "1:1022743451273:web:2c3519756febf40e189858",
    measurementId: "G-1P5CZ9YEM1"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);


function RedirectToInstagram() {
  React.useEffect(() => {
    window.location.href = 'https://instagram.com/luvnnect';
  }, []);

  return null;
}


  return (
    <Routes>
      <Route path="/" element={<RedirectToInstagram />} />
      <Route path="/mbti" element={<MbtiView />} />
      <Route
        path="/mbti/luv" element={<MbtiluvView />}
      />
      <Route path="/user/signout" element={< UserSignout/>} />
      <Route path="/user/pwreset" element={< UserPwreset/>} />
    </Routes>
  );
}

export default App;
